@font-face {
  font-family: 'mainFontEn';
  src: url('./assets/fonts/English/Taminat_English.ttf') format('truetype');
}

@font-face {
  font-family: 'mainFontAr';
  src: url('./assets/fonts/Arabic/Taminat_Arabic.otf.ttf') format('truetype');
}

:root {
  --primary: #005891;
  --primary-light: #83a4db;
  --primary-lighter: #b4c6e8;
  --secondary: #cfd8dc;
  --secondary-light: #eceff1;
  --secondary-dark: #b0bec5;
  --text-light: #ececec;
  --text-dark: #2e3039 --light-bg: #ececec;
  --dark-bg: #2e3039;
  --grey-bg: #666666;
  --light-gray-bg: #a7a9ac;
  --navbar-height: 6em;
}

* {
  font-family: 'mainFontAr', 'mainFontEn' !important;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  text-align: right;
}

body {
  font-family: 'mainFontAr', 'mainFontEn';
  display: flex;
  flex-direction: column;
  margin: 0;
  overflow-x: hidden;
}

/* Hide scrollbar for Chrome, Safari and Opera */
*::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
* {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

h1,
h2,
h3,
h4,
h5,
h6,
label,
span,
p,
ul,
ol,
li,
a,
input,
textarea,
able,
tr,
th,
td,
thead,
tbody,
tfoot,
button {
  font-family: 'mainFontAr', 'mainFontEn';
  color: #2e3039;
  text-decoration: none;
}

/* Chrome, Safari, Edge, Opera */
input[type='number']::-webkit-outer-spin-button,
input[type='number']::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
  appearance: textfield;
}

.app-content {
  background: #eceff1;
  flex-grow: 1;
  min-height: 100vh;
}
